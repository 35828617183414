import AulaService from '../../services/AulaService'
import router from '../../router'
import axios from 'axios'
const state = () => ({
    loadingAulas: false,
    aula: null,
    aulas: [],
    total: 0,
    pages: 0,
    page: 1,
    show_modal_new_aula: false
})
  
const mutations = {
    SET_AULA: (state, payload) => {
        state.aula = payload
    },
    SET_AULAS: (state, payload) => {
        if(payload.pageNumber == 1) {
            state.aulas = payload.itens
        }else{
            state.aulas = [...state.aulas, ...payload.itens]
        }
        state.page =  payload.pageNumber
        state.pages =  payload.pages
        state.total =  payload.total

    },
    SET_LOADING_AULAS: (state, payload) => {
        state.loadingAula = payload
    },
    SET_SHOW_MODAL_AULA: (state, payload) => {
        state.show_modal_new_aula = payload
    },
}

const actions = {
    async fetchAula({commit}, id){
            commit('SET_LOADING_AULAS', true)
            const response = await AulaService.getOne(id);
            const dataAula = response.data
            if(dataAula){
                dataAula.createdAt = await formatarData(dataAula.createdAt);
                dataAula.updatedAt = await formatarData(dataAula.updatedAt);
            }
            commit('SET_AULA', dataAula)
            commit('SET_LOADING_AULAS', false)
    },
    async fetchAulas({commit}, { keyword = '', pageNumber = 1, sortBy = 'mais recentes' }){
        try{
            commit('SET_LOADING_AULAS', true)
            const response = await AulaService.get(keyword, pageNumber, sortBy);
            commit('SET_AULAS', response.data)
            commit('SET_LOADING_AULAS', false)
        } catch (error) {
            commit('SET_LOADING_AULAS', false);
            commit('LOG_USER_OUT');
            commit('SET_ALERT', {
                heading: 'error',
                message: error.response.data.message
            });
        }
    },
    async createAula({commit}, data){
        try{
            commit('SET_LOADING_AULAS', true)
            const response = await AulaService.create(data);
            commit('SET_ALERT', {
                heading: 'success',
                message: response.data.message
            });
            const newAula = response.data.item
            if(response && newAula) {
                router.push({path: `/form_aula/${newAula.id}`});
            }
            commit('SET_LOADING_AULAS', false)
        } catch (error) {
            commit('SET_LOADING_AULAS', false);
            commit('SET_ALERT', {
                heading: 'error',
                message: error.response.data.message
            });
        }
    },
    async updadeAula({commit}, data){
        try{
            commit('SET_LOADING_AULAS', true)
            const response = await AulaService.update(data);
            commit('SET_ALERT', {
                heading: 'success',
                message: response.data.message
            });
            commit('SET_LOADING_AULAS', false)
        } catch (error) {
            commit('SET_LOADING_AULAS', false);
            commit('SET_ALERT', {
                heading: 'error',
                message: error.response.data.message
            });
        }
    },
    async fetchDadosThumbnails({ commit }, videoId) {
        try {
            commit('SET_LOADING_AULAS', true)
            const apiUrl = `https://vimeo.com/api/oembed.json?url=https://player.vimeo.com/video/${videoId}`;
            const response = await axios.get(apiUrl);
            let thumbnailUrl = response.data;

            const seconds = thumbnailUrl.duration;

            const hours = Math.floor(seconds / 3600);
            const minutes = Math.floor((seconds % 3600) / 60);
            const remainingSeconds = seconds % 60;

            const formattedHours = String(hours).padStart(2, '0');
            const formattedMinutes = String(minutes).padStart(2, '0');
            const formattedSeconds = String(remainingSeconds).padStart(2, '0');

            thumbnailUrl.time = `${formattedHours}:${formattedMinutes}:${formattedSeconds}`;
            commit('SET_LOADING_AULAS', false)
            return thumbnailUrl;
        } catch (error) {
            commit('SET_LOADING_AULAS', false)
            console.error('Erro ao buscar a miniatura do vídeo:', error);
            return null;
        }
    }
}

async function formatarData(data) {
    const dataObj = new Date(data);
    const ano = dataObj.getUTCFullYear();
    const mes = (dataObj.getUTCMonth() + 1).toString().padStart(2, '0');
    const dia = dataObj.getUTCDate().toString().padStart(2, '0');
    const hora = dataObj.getUTCHours().toString().padStart(2, '0');
    const minuto = dataObj.getUTCMinutes().toString().padStart(2, '0');

    return `${ano}-${mes}-${dia} ${hora}:${minuto}`;
}

const getters = {
    getAula: state => state.aula,
    getAulas: state => state.aulas,
    getLoadingAula: state => state.loadingAula,
    getShowModalNewAula: state => state.show_modal_new_aula
}

export default {
    state,
    getters,
    mutations,
    actions
};