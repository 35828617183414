<template>
    <div class="flex justify-between">
        <div>
            <div @click="SET_SHOW_SIDE_NAV(!getshowSideNav)" class="flex items-center justify-between flex-shrink-0" >
                <span class="px-3 mt-2 py-1 text-xl font-semibold leading-8 tracking-wider uppercase whitespace-nowrap">
                    <img alt="Logo" src="/img/logo_SBH.png" class="h-10 object-cover">
                </span>
            </div>
            <div class="flex-1 overflow-hidden hover:overflow-y-auto border-t border-gray-100">
                <div class="px-2">
                    <ul class="space-y-1 border-t border-gray-100 pt-4 p-2 text-sm">
                    
                        <li v-for="item in getItensNavBar" :key="item.id" :class="'group/principal-'+item.id">
                            <span v-if="item.sublinks.length" class="relative transition">
                                <input class="peer hidden" type="checkbox" id="menu-1" checked />
                                <button class="flex w-full peer relative transition-all duration-100 ease-in-out bg-red-600/50">
                                    <label for="menu-1" class="absolute inset-0 h-full w-full cursor-pointer"></label>
                                    <router-link :to="item.url" :title="item.name" class="flex items-center p-2 space-x-2 rounded-md hover:bg-gray-100" :class="{'justify-center': !getshowSideNav}">
                                        <div v-html="item.svg" :class="getshowSideNav ? 'h-5 w-5' : 'h-4 w-4'"></div>
                                        <span :class="{ 'lg:hidden': !getshowSideNav }" class="">{{item.name}}</span>
                                    </router-link>
                                </button>
                                <svg :class="{ 'lg:hidden': !getshowSideNav }" xmlns="http://www.w3.org/2000/svg" class="absolute right-0 top-4 ml-auto opacity-75 mr-5 h-4 text-gray-600 transition peer-checked:rotate-180 peer-hover:text-rose-600" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2"><path stroke-linecap="round" stroke-linejoin="round" d="M19 9l-7 7-7-7" /></svg>
                                <ul  :class="{ 'lg:hidden': !getshowSideNav }" class="duration-400 flex m-2 max-h-0 flex-col overflow-hidden rounded-lg bg-gray-100 font-medium transition-all duration-300 peer-checked:max-h-96">
                                    <router-link :to="sublink.url" :title="sublink.name" v-for="sublink in item.sublinks" :key="sublink.id" class="flex m-1 cursor-pointer border-l-rose-600 py-1 pl-5 text-sm text-gray-600 transition-all duration-100 ease-in-out hover:border-l-4 hover:text-rose-600">
                                        {{ sublink.name }}
                                    </router-link>
                                </ul>
                            </span>
                            <router-link v-else :to="item.url" :title="item.name" class="flex items-center p-2 space-x-2 rounded-md hover:bg-gray-100" :class="{'justify-center': !getshowSideNav}">
                                <div v-html="item.svg"></div>
                                <span :class="{ 'lg:hidden': !getshowSideNav }" class="">{{ item.name }}</span>
                            </router-link>
                        </li>
                    </ul>
                </div>
            </div>
        </div>

        <div class="sticky inset-x-0 bottom-0 border-t border-gray-100 bg-white p-2">
            <button @click="LOG_USER_OUT()" type="button" class="group relative flex w-full justify-center rounded-lg border px-2 py-1.5 text-sm text-gray-500 hover:bg-gray-50 hover:text-gray-700">
                <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 opacity-75" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2"><path stroke-linecap="round" stroke-linejoin="round" d="M17 16l4-4m0 0l-4-4m4 4H7m6 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h4a3 3 0 013 3v1" /></svg>
                <span :class="{'lg:hidden': !getshowSideNav}" class="ml-3"> Sair </span>
            </button>
        </div>
    </div>
</template>

<script>
    import { mapGetters, mapMutations } from 'vuex';

    export default {
        name: "SideNavComponnent",
        components: {},
        data() {
            return {
            }
        },
        methods: {
            ...mapMutations(['SET_SHOW_SIDE_NAV', 'LOG_USER_OUT'])
        },
        computed: {
            ...mapGetters(['getshowSideNav', 'getItensNavBar'])
        }
    }
</script>
<style scoped>
    button {
        background: transparent;
    }
</style>