import { createRouter, createWebHistory } from 'vue-router'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: () => import('@/views/HomeView.vue')
  },
  {
    path: '/auth',
    name: 'auth',
    component: () => import('@/views/LoginView.vue')
  },
  {
    path: '/users',
    name: 'Usuários',
    component: () => import('@/views/users/List_UsersAulas.vue')
  },
  {
    path: '/users/notas',
    name: 'Nota dos Usuários',
    component: () => import('@/views/users/List_UsersNotas.vue')
  },
  {
    path: '/form_user/',
    name: 'form_user',
    component: () => import('@/views/users/FormUser.vue'),
    children: [{
      path: ':id_user', name: 'editar_user', 
      component: () => import('@/views/users/FormUser.vue')
    }]
  },
  {
    path: '/avaliacoes/',
    name: 'Avaliações',
    component: () => import('@/views/avaliacoes/List_Avaliacoes.vue')
  },
  {
    path: '/form_avaliacao/',
    name: 'form_avaliacao',
    component: () => import('@/views/avaliacoes/FormAvaliacao.vue'),
    children: [{
      path: ':id_avaliacao', 
      name: 'editar_avaliacao', 
    component: () => import('@/views/avaliacoes/FormAvaliacao.vue')
    }]
  },
  {
    path: '/aulas',
    name: 'Aulas',
    component: () => import('@/views/aulas/List_Aulas.vue')
  },
  {
    path: '/form_aula/',
    name: 'form_aula',
    component: () => import('@/views/aulas/FormAula.vue'),
    children: [{
      path: ':id_aula', 
      name: 'editar_aula', 
    component: () => import('@/views/aulas/FormAula.vue')
    }]
  },
  {
    path: '/banners',
    name: 'Banners',
    component: () => import('@/views/banners/List_Banners.vue')
  },
  {
    path: '/form_banner/',
    name: 'form_banner',
    component: () => import('@/views/banners/FormBanner.vue'),
    children: [{
      path: ':id_banner', 
      name: 'editar_banner', 
    component: () => import('@/views/banners/FormBanner.vue')
    }]
  },
  {
    path: '/subjects',
    name: 'Disciplinas',
    component: () => import('@/views/subjects/List_Subjects.vue')
  },
  {
    path: '/form_subject/',
    name: 'form_subject',
    component: () => import('@/views/subjects/FormSubject.vue'),
    children: [{
      path: ':id_subject', 
      name: 'editar_subject', 
    component: () => import('@/views/subjects/FormSubject.vue')
    }]
  },
  {
    path: '/categories',
    name: 'Categorias',
    component: () => import('@/views/categories/List_Categories.vue')
  },
  {
    path: '/form_category/',
    name: 'form_category',
    component: () => import('@/views/categories/FormCategory.vue'),
    children: [{
      path: ':id_category', 
      name: 'editar_category', 
    component: () => import('@/views/categories/FormCategory.vue')
    }]
  },
  {
    path: '/authors',
    name: 'Autores',
    component: () => import('@/views/authors/List_Authors.vue')
  },
  {
    path: '/form_author/',
    name: 'form_author',
    component: () => import('@/views/authors/FormAuthor.vue'),
    children: [{
      path: ':id_author', 
      name: 'editar_author', 
    component: () => import('@/views/authors/FormAuthor.vue')
    }]
  },
  {
    path: '/notifications',
    name: 'Notificações',
    component: () => import('@/views/notifications/List_Notifications.vue')
  },
  {
    path: '/form_notification/',
    name: 'form_notification',
    component: () => import('@/views/notifications/FormNotification.vue'),
    children: [{
      path: ':id_notification', 
      name: 'editar_notification', 
    component: () => import('@/views/notifications/FormNotification.vue')
    }]
  },
  {
    path: '/certificates',
    name: 'Certificados',
    component: () => import('@/views/certificates/List_Certificates.vue')
  },
  {
    path: '/form_certificate/',
    name: 'form_certificate',
    component: () => import('@/views/certificates/FormCertificates.vue'),
    children: [{
      path: ':id_certificate', 
      name: 'editar_certificate', 
    component: () => import('@/views/certificates/FormCertificates.vue')
    }]
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'Error 404',
    component: () => import('@/views/Error404.vue')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {
  window.scrollTo(0, 0) // Isso fará a rolagem para o topo da página
  next()
})

export default router
