import Api from './ApiService'

export default {
    get(keyword, pageNumber, pageSize, order) {
        return Api().get(`certificado?keyword=${keyword}&pageNumber=${pageNumber}&pageSize=${pageSize}&orderColumn=${order.column}&orderSort=${order.sort}`);
    },
    getOne(id) {
        return Api().get(`certificado/${id}`);
    },
    async generate(certificado) {
        try {
            const response = await Api().get(`certificado/gerar/${certificado.id}`, {
                responseType: 'blob' // Define o tipo da resposta como blob para arquivos binários
            });
            
            // Crie uma URL para o blob e simule o download
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `Certificado ${certificado.name}.pdf`); // Nome do arquivo no download
            document.body.appendChild(link);
            link.click();

            // Limpa o link depois do download
            document.body.removeChild(link);
            window.URL.revokeObjectURL(url);
        } catch (error) {
            console.error('Erro ao baixar o certificado:', error);
        }
    },
    create(formData){
        const config = {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          };
        return Api().post(`certificado`, formData, config);
    },
    associateUsers(id) {
        return Api().post(`certificado/associar-certificado/${id}`);
    },
    update(formData){
        const config = {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          };
        return Api().put(`certificado/${formData.id}`, formData, config);
    },
    delete(id) {
        return Api().delete(`certificado/${id}`);
    }
}