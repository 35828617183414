import router from '../../router'
import CertificadoService from '../../services/CertificadoService'

const state = () => ({
    loadingCertificados: false,
    certificado: null,
    certificados: [],
    total: 0,
    pages: 0,
    page: 1,
    show_modal_new_certificado: false
})
  
const mutations = {
    SET_CERTIFICADO: (state, payload) => {
        state.certificado = payload
    },
    SET_CERTIFICADOS: (state, payload) => {
        if(payload.pageNumber == 1) {
            state.certificados = payload.itens
        }else{
            state.certificados = [...state.certificados, ...payload.itens]
        }
        state.page =  payload.pageNumber
        state.pages =  payload.pages
        state.total =  payload.total

    },
    SET_LOADING_CERTIFICADOS: (state, payload) => {
        state.loadingCertificado = payload
    },
    SET_SHOW_MODAL_CERTIFICADO: (state, payload) => {
        state.show_modal_new_certificado = payload
    },
}

const actions = {
    async fetchCertificado({commit}, certificado){
        try{
            commit('SET_LOADING_CERTIFICADOS', true)
            const response = await CertificadoService.getOne(certificado);
            var dataCertificate = response.data.item;
            if(dataCertificate){
               
                dataCertificate.createdAt = await formatarData(dataCertificate.createdAt);
                dataCertificate.updatedAt = await formatarData(dataCertificate.updatedAt);
            }
            commit('SET_CERTIFICADO', dataCertificate)
            commit('SET_LOADING_CERTIFICADOS', false)
        } catch (error) {
            commit('SET_LOADING_CERTIFICADOS', false);
            if(error && error.response && error.response.data.message == "Sessão expirada") {
                commit('SET_REDIRECT', router.currentRoute.value.path);
            }
            // commit('LOG_USER_OUT');
            commit('SET_ALERT', {
                heading: 'error',
                message: error.response.data.message
            });
        }
    },
    async gerarCertificado({commit}, certificado){
        try{
            commit('SET_LOADING_CERTIFICADOS', true)
            const response = await CertificadoService.generate(certificado);
            commit('SET_CERTIFICADO', response.data)
            commit('SET_LOADING_CERTIFICADOS', false)
        } catch (error) {
            commit('SET_LOADING_CERTIFICADOS', false);
            if(error && error.response && error.response.data.message == "Sessão expirada") {
                commit('SET_REDIRECT', router.currentRoute.value.path);
            }
            commit('LOG_USER_OUT');
            commit('SET_ALERT', {
                heading: 'error',
                message: error.response.data.message
            });
        }
    },
    async fetchCertificados({commit}, params = {}){
        try{
            commit('SET_LOADING_CERTIFICADOS', true)
            const { keyword = '', pageNumber = 1, pageSize = 12, order = { name: 'ID', column: 'ID', sort: 'ASC' } } = params;
            const response = await CertificadoService.get(keyword, pageNumber, pageSize, order);
            commit('SET_CERTIFICADOS', response.data)
            commit('SET_LOADING_CERTIFICADOS', false)
        } catch (error) {
            commit('SET_LOADING_CERTIFICADOS', false);
            if(error && error.response && error.response.data.message == "Sessão expirada") {
                commit('SET_REDIRECT', router.currentRoute.value.path);
            }
            commit('LOG_USER_OUT');
            commit('SET_ALERT', {
                heading: 'error',
                message: error.response.data.message
            });
        }
    },
    async createCertificado({commit}, data){
        try{
            commit('SET_LOADING_CERTIFICADOS', true)
            const response = await CertificadoService.create(data);
            commit('SET_ALERT', {
                heading: 'success',
                message: response.data.message
            });
            router.push({path: `/form_certificate/${response.data.item.id}`});
            commit('SET_SHOW_MODAL_CERTIFICADO', false)
            commit('SET_LOADING_CERTIFICADOS', false)
        } catch (error) {
            commit('SET_LOADING_CERTIFICADOS', false);
            commit('SET_ALERT', {
                heading: 'error',
                message: error.response.data.message
            });
        }
    },
    async associateUsersCertificate({commit}, id_certificado){
        try{
            commit('SET_LOADING_CERTIFICADOS', true)
            const response = await CertificadoService.associateUsers(id_certificado);
            commit('SET_ALERT', {
                heading: 'success',
                message: response.data.message
            });
            commit('SET_LOADING_CERTIFICADOS', false)
            window.location.reload();
        } catch (error) {
            commit('SET_LOADING_CERTIFICADOS', false);
            commit('SET_ALERT', {
                heading: 'error',
                message: error.response.data.message
            });
        }
    },
    async updadeCertificado({commit}, data){
        try{
            commit('SET_LOADING_CERTIFICADOS', true)
            const response = await CertificadoService.update(data);
            commit('SET_ALERT', {
                heading: 'success',
                message: response.data.message
            });
            router.push({path: `/certificates`});
            commit('SET_LOADING_CERTIFICADOS', false)
        } catch (error) {
            commit('SET_LOADING_CERTIFICADOS', false);
            commit('SET_ALERT', {
                heading: 'error',
                message: error.response.data.message
            });
        }
    },
    async deleteCertificado({state, commit}, id) {
        try{
            commit('SET_LOADING_CERTIFICADOS', true);
            if(state.certificado.id == id){
                const response = await CertificadoService.delete(id);
                router.push({path: `/certificates`});
                commit('SET_ALERT', {
                    heading: 'success',
                    message: response.data.message
                });
            }
            commit('SET_LOADING_CERTIFICADOS', false);
        } catch (error) {
            commit('SET_LOADING_CERTIFICADOS', false);
            commit('SET_ALERT', {
                heading: 'error',
                message: error.response.data.message
            });
        }
    }
}

async function formatarData(data) {
    const dataObj = new Date(data);
    const ano = dataObj.getUTCFullYear();
    const mes = (dataObj.getUTCMonth() + 1).toString().padStart(2, '0');
    const dia = dataObj.getUTCDate().toString().padStart(2, '0');
    const hora = dataObj.getUTCHours().toString().padStart(2, '0');
    const minuto = dataObj.getUTCMinutes().toString().padStart(2, '0');

    return `${ano}-${mes}-${dia} ${hora}:${minuto}`;
}

const getters = {
    getCertificado: state => state.certificado,
    getCertificados: state => state.certificados,
    getLoadingCertificado: state => state.loadingCertificado,
    getShowModalNewCertificado: state => state.show_modal_new_certificado
}

export default {
    state,
    getters,
    mutations,
    actions
};